jQuery(document).ready(function($) {
	jQuery('.home-carousel .slick').slick({
		dots: true,
		arrows: false,
		autoplay: false,
		autoplaySpeed: 5000,
		pauseOnHover: false,
		fade: true,
		infinite: true
	});
});
