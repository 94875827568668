jQuery(document).ready(function($) {
});

jQuery(window).load(function(){
	if( isBreakpoint('sm') || isBreakpoint('md') || isBreakpoint('lg') ){
		jQuery('.titles-effect').each(function(){
			var $text = jQuery(this);
			$text.waypoint({
				handler: function(direction) {
					var $this = jQuery(this.element);

					if( !$this.hasClass('animated') ){
						$this.addClass('animated');

						var mySplitText = new SplitText($text, {type:"words"}),
							splitTextTimeline = new TimelineLite();

						mySplitText.split({
							type:"lines"
						});
						
						TweenLite.set($text, {
							opacity:1
						});

						splitTextTimeline.staggerFrom(mySplitText.lines, 0.5, {
							opacity:0,
							y: 150
						}, 0.1);
					}
				},
				offset: 'bottom-in-view'
			});
		});
	}
});
